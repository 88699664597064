import UQRCode from 'uqrcodejs';
import moment from 'moment'


export const timestamp = (stamp) => {//时间戳转时间
    return moment(stamp).add(0, 'h').format("YYYY-MM-DD HH:mm:ss")
}

export const DivideTextAndNumbers = (v) => {
    let array = v.split("")
    let number = ""
    let word = ""
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (numbers.indexOf(item) !== -1) {
            number += item
        } else {
            if (item !== ',') word += item
        }
    }
    return {
        number: parseFloat(number),
        word
    }
}

export const fontSize = (size) => {
    // 获取屏幕宽度
    let width = document.body.clientWidth;
    const ratio = width / 1920
    // 取下整
    return parseInt(size * ratio)
}

export const findCloseNum = (arr, num) => { //找出最接近的值
    var index = 0; // 保存最接近数值在数组中的索引
    var old_value = Number.MAX_VALUE; // 保存差值绝对值，默认为最大数值
    for (var i = 0; i < arr.length; i++) {
        var new_value = Math.abs(arr[i] - num); // 新差值
        if (new_value <= old_value) { // 如果新差值绝对值小于等于旧差值绝对值，保存新差值绝对值和索引
            if (new_value === old_value && arr[i] < arr[index]) { // 如果数组中两个数值跟目标数值差值一样，取大
                continue;
            }
            index = i;
            old_value = new_value;
        }
    }
    return arr[index] // 返回最接近的数值
}

const get_qrcode_img = data => { //获取二维码图片dom
    return new Promise(async resolve => {
        // 获取uQRCode实例
        let qr = new UQRCode();
        qr.errorCorrectLevel = 1
        // 设置二维码内容
        qr.data = data
        // 设置二维码大小，必须与canvas设置的宽高一致
        qr.size = 160;
        // 调用制作二维码方法
        qr.make();
        // 获取canvas元素
        let canvas = document.createElement('canvas')
        canvas.width = 160
        canvas.height = 160
        // 获取canvas上下文
        let canvasContext = canvas.getContext("2d");
        // 设置uQRCode实例的canvas上下文
        qr.canvasContext = canvasContext;
        // 调用绘制方法将二维码图案绘制到canvas上
        qr.drawCanvas();
        let img = new Image(160, 160)
        img.src = canvas.toDataURL()
        await new Promise(resolve => img.onload = () => resolve())
        resolve(img)
    })
}
export default {
    get_qrcode_img
}

export const zs = async (transactionHash = "", blockNumber = "", agencyName = "", createdAt = "", sealer = "", createTime, isCAQS = false) => {
    return new Promise(async resolve => {
        let QRCodeImg = await get_qrcode_img(isCAQS ? 'https://www.caqsgap.cn/#/?hash=' + transactionHash : transactionHash)
        let canvas = document.createElement('canvas')
        canvas.id = "canvas"
        canvas.width = 1116;
        canvas.height = 1563

        let ctx = canvas.getContext("2d");
        let img = new Image(1116, 1563)
        img.src = require("@/assets/image/zs.png?t=1")
        await new Promise(resolve => img.onload = () => resolve())
        ctx.drawImage(img, 0, 0, 1116, 1563);

        ctx.font = "30px 黑体";
        ctx.fillStyle = '#333333'
        ctx.fillText(transactionHash.slice(0, 33), 398 + 10, 610);
        ctx.fillText(transactionHash.slice(33, transactionHash.length), 398 + 10, 610 + 40);
        ctx.font = "28px 黑体";
        ctx.fillText(blockNumber, 398 + 10, 715)
        ctx.fillText(agencyName, 398 + 10, 715 + 62)
        ctx.fillText(createdAt, 398 + 10, 715 + 125)
        ctx.fillText(sealer, 398 + 10, 715 + 192)
        ctx.fillText(createTime, 398 + 10, 715 + 255)

        ctx.drawImage(QRCodeImg, 778, 1234, 160, 160);
        resolve(canvas.toDataURL())
    })


}

export const savePicture = base64 => { //base64保存为图片  H5
    let arr = base64.split(',');
    let bytes = new Buffer.from(arr[1], "base64").toString("binary")
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) ia[i] = bytes.charCodeAt(i)
    let blob = new Blob([ab], { type: 'application/octet-stream' });
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = "证书.png";
    a.click()
}


export const isCAQS = (transaction = {}) => {
    if (transaction.input.result.length === 0 || typeof transaction.input.result[0] !== 'string') return false
    return transaction.input.result[0].indexOf("isCAQS") !== -1
}